<template>
  <div class="dealer-subscriptions-tab" v-loading="loading">
    <div class="filter-block">
      <div class="button-group">
        <el-button type="success" size="small" @click="downloadTableData">
          <i class="el-icon-download"></i>
        </el-button>
      </div>
    </div>
    <el-table
      :data="tableData.data"
      class="pond-details-table"
      max-height="200px"
      border
      style="width: 100%; margin-bottom: 15px;"
      size="mini"
    >
      <el-table-column
        v-for="(col, index) of columns"
        :prop="col.prop"
        :label="col.label"
        :key="index"
        :min-width="col.width"
        :align="col.align"
      >
        <template slot="header" slot-scope="scope">
          {{ columns[scope.$index].label }}
          <span v-if="columns[scope.$index].info">
            <el-tooltip
              class="item"
              effect="light"
              :content="columns[scope.$index].info"
              placement="top"
              ><i class="el-icon-info icon"
            /></el-tooltip>
          </span>
        </template>
        <template slot-scope="scope">
          <span v-if="col.type == 'date'">
            {{
              scope.row[col.prop]
                ? moment(scope.row[col.prop]).format(col.date_format)
                : "-"
            }}
          </span>
          <span v-else>{{ scope.row[col.prop] }} </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import moment from "moment";

export default {
  mixins: [errorHandlerMixin, filtersMixin],
  components: {},
  props: ["pond", "pond_name", "unassigned_data"],
  data: function() {
    return {
      moment,
      loading: false,
      columns: [
        {
          label: "ShrimpTalk ID",
          prop: "shrimp_talk_code",
          sort: true,
          width: "150"
        },
        // {
        //   label: "Installation Date",
        //   prop: "installed_date",
        //   sort: false,
        //   width: "100",
        //   type: "date",
        //   date_format: "DD-MMM-YYYY",
        //   info: "Date on which device is assigned to the pond",
        // },
        {
          label: "Start Date",
          prop: "subscription_start_date",
          sort: true,
          width: "100",
          type: "date",
          date_format: "DD-MMM-YYYY",
          info:
            "Date on which subscription gets started which is based on device in Automode & Feeder dispenses feed"
        },
        {
          label: "End Date",
          prop: "subscription_end_date",
          sort: true,
          width: "100",
          type: "date",
          date_format: "DD-MMM-YYYY",
          info: "Expiry of the subscription plan"
        }
      ],
      tableData: {
        year: "2022",
        data: []
      },
      searchString: "",
      pondId: "",
      pondName: "",
      start_date: "2021-01-01",
      end_date: "2021-12-31",
      date_format: "DD-MMM-YYYY",
      monthfilter: ""
    };
  },

  computed: {
    ...mapGetters("accountManager", {
      getDealerSubscriptions: "getDealerSubscriptions",
      getCustomerPondShrimptalks: "getCustomerPondShrimptalks"
    }),
    columnsObject() {
      return {
        customer: {
          prop: "customer",
          name: "customer",
          label: "dealer.customer",
          required: true,
          sortable: true
        },
        plan_type: {
          prop: "type",
          name: "plan_type",
          label: "dealer.plan_type",
          required: true,
          sortable: false
        },
        duration_years: {
          prop: "duration_years",
          name: "duration_years",
          label: "dealer.no_of_years",
          required: true,
          sortable: false
        },
        price: {
          name: "price",
          prop: "price",
          label: "dealer.price",
          required: true,
          sortable: false
        },
        discount: {
          prop: "discount",
          name: "discount",
          label: "dealer.discount",
          required: true
        },
        final_price: {
          prop: "final_price",
          name: "final_price",
          label: "dealer.final_price",
          required: true
        }
      };
    },
    filterTableData() {
      if (!this.searchString) return this.tableData;
      return this.tableData.filter((data) => {
        return data.title.toLowerCase().includes(this.searchString);
      });
    }
  },
  async mounted() {
    this.handleLoadData();
  },
  methods: {
    ...mapActions("accountManager", {
      fetchAllSubscriptions: "fetchAllSubscriptions",
      fetchCustomerPondShrimptalks: "fetchCustomerPondShrimptalks"
    }),
    handleLoadData() {
      try {
        this.loading = true;
        this.pondId = this.pond || this.$route.query.pond;

        this.pondName = this.pond_name || this.$route.query.pond_name;

        this.tableData.data = this.updateSTDataIntoTable(this.unassigned_data);
        this.loading = false;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },

    updateSTDataIntoTable(unAssigned) {
      return unAssigned.st_ids.map((e) => {
        return {
          shrimp_talk_code: e,
          installed_date: unAssigned.min_installed_date,
          subscription_start_date: unAssigned.subscription_start_date,
          subscription_end_date: unAssigned.subscription_end_date
        };
      });
    },

    downloadTableData(data) {
      let csv = this.columns.map((e) => e.label).join(",") + "\n";

      csv = "Pond," + csv;
      // merge the data with CSV

      this.tableData.data.forEach((row) => {
        let rowdata = this.pondName + ",";
        this.columns.forEach((e) => {
          if (row[e.prop]) {
            let value = "";

            if (e.prop === "pond" || e.prop === "location") {
              value = row[e.prop].name;
            } else if (
              e.prop === "installed_date" ||
              e.prop === "subscription_start_date" ||
              e.prop === "subscription_end_date"
            ) {
              value = moment(row[e.prop]).format(this.date_format);
            } else {
              value = row[e.prop];
            }
            console.log({ value });
            rowdata = rowdata + value + ",";
          } else {
            rowdata = rowdata + "" + ",";
          }
        });
        rowdata = rowdata + "\n";

        csv += rowdata;
      });

      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";

      // provide the name for the CSV file to be downloaded
      hiddenElement.download =
        "POND_ALL_SHRMIPTALKS_" +
        this.start_date +
        "_" +
        this.end_date +
        ".csv";
      hiddenElement.click();
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Total Receivables";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    }
  }
};
</script>

<style lang="scss">
.dealer-subscriptions-tab {
  height: 100%;
  .el-table__body-wrapper {
    height: inherit;
    overflow: auto;
  }
  .el-date-editor {
    .el-input__icon {
      line-height: 23px !important;
    }
  }
  .filter-block {
    margin-bottom: 0.5em;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;
    .el-date-editor--monthrange.el-input__inner {
      width: 100%;
      height: 29px !important;
    }
    .button-group {
      .el-button {
        line-height: 1;
        padding: 6px 7px;
        height: auto;
        border-radius: 3px;
      }
    }
  }
  .pond-stats {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    p {
      font-size: 14px;
    }
    span {
      margin-left: 5px;
      display: inline-block;
      font-weight: 500;
      padding: 2px 5px;
      background-color: #cee7fc;
      border-radius: 3px;
    }
  }
}
</style>
