<template>
  <div class="account-manager-customer-pond-summary" v-loading="loading">
    <!---      toolbar-layout="year_filter,last_updated, plan_type, plan_name"-->
    <layout-dealer-card
      search-property="year"
      toolbar-layout="year_filter,download_btn"
      @loadData="handleSearch"
      @onDownloadData="downloadTableData"
      v-show="!loading"
    >
      <template v-slot:heading>
        <span class="material-icons-round" style="color: #33a02c">
          card_membership
        </span>
        <p style="margin-left: 10px">
          {{ ftm__capitalize($t("dealer.subscriptions")) }}
        </p>
        <div class="selection-box">
          <er-select
            size="mini"
            v-model="customer"
            placeholder="Select Customer"
            @change="handleSearch"
          >
            <el-option
              v-for="(item, index) in allCustomers"
              :key="index"
              :label="`${item.first_name} ${item.last_name}`"
              :value="item._id"
            >
            </el-option>
          </er-select>
        </div>
      </template>

      <template slot="sub-heading"> Summary </template>
      <template slot="table">
        <el-table
          :data="tableData.data"
          style="width: 100%; height: calc(100vh - 225px)"
          :summary-method="getSummaries"
          show-summary
          :cell-class-name="getCellClass"
          size="mini"
        >
          <el-table-column
            v-for="(col, index) of columns"
            :prop="col.prop"
            :label="col.label"
            :key="index"
            :min-width="col.width"
            :align="col.align"
          >
            <template slot="header" slot-scope="scope">
              {{ columns[scope.$index].label }}
              <span v-if="columns[scope.$index].info">
                <el-tooltip
                  class="item"
                  effect="light"
                  :content="columns[scope.$index].info"
                  placement="top"
                  ><i class="el-icon-info icon"
                /></el-tooltip>
              </span>
            </template>
            <template slot-scope="scope">
              <span v-if="col.prop == 'pond'" class="location-name">
                <el-button
                  class="text-button"
                  type="text"
                  @click="showPondSTDetails(scope.row[col.prop])"
                >
                  {{ scope.row[col.prop].name }}
                </el-button>
              </span>

              <span v-else-if="col.prop == 'location'" class="location-name">
                {{ scope.row[col.prop].name }}
              </span>
              <span v-else-if="propsForAmountFormat[col.prop]">
                {{ getLocaleString(scope.row[col.prop]) || "-" }}
              </span>
              <span v-else :class="col.value_type">
                {{ scope.row[col.prop] }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template slot="table-legends">
        <div>
          <el-row type="flex" align="middle">
            <el-col sm="12">
              <div class="table-info text-left">
                <span>STs without subscription : </span>
                <span>
                  <strong> {{ tableData.total_unnassinged_count }} </strong>
                </span>
              </div>
            </el-col>
            <el-col sm="12">
              <div class="table-legends">
                <div class="actuals">
                  <span class="icon"></span>
                  <span>Actuals </span>
                </div>
                <div class="projections">
                  <span class="icon"></span>
                  <span>Projections </span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </template>
    </layout-dealer-card>

    <el-dialog
      class="pond-details-dialog"
      :title="selectedPondName"
      :visible.sync="dialogPondDetailsVisible"
      width="55%!important"
      top="20px"
    >
      <pondDetails
        v-if="dialogPondDetailsVisible"
        :pond="selectedPondId"
        :pond_name="selectedPondName"
      >
      </pondDetails>
    </el-dialog>

    <el-dialog
      class="pond-details-dialog"
      :title="selectedPondName"
      :visible.sync="dialogUnassignedPondDetailsVisible"
      width="800px"
      top="20px"
    >
      <unAssignedPondDetails
        v-if="dialogUnassignedPondDetailsVisible"
        :pond="selectedPondId"
        :pond_name="selectedPondName"
        :unassigned_data="tableData.total_unnassinged_details"
      >
      </unAssignedPondDetails>
    </el-dialog>
  </div>
</template>

<script>
import layoutDealerCard from "@/components/dealer/layoutDealerCard";
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import moment from "moment";
import redirectsMixin from "@/mixins/redirectsMixin";

import pondDetails from "./pondDetails";
import unAssignedPondDetails from "./unAssignedPondDetails";

export default {
  mixins: [errorHandlerMixin, filtersMixin, redirectsMixin],
  components: {
    layoutDealerCard,
    pondDetails,
    unAssignedPondDetails
  },
  data: function() {
    return {
      loading: false,
      columns: [
        {
          label: "Location",
          prop: "location",
          sort: true,
          width: "130",
          fixed: "left"
        },
        {
          label: "Pond",
          prop: "pond",
          sort: false,
          width: "90",
          fixed: "left",
          action: {
            type: "route-change",
            toRoute: "/dealer/subscriptions/pond-details"
          }
        },
        {
          label: "Total STs",
          prop: "total_sts",
          sort: true,
          width: "70",
          fixed: "left",
          align: "center"
        },
        {
          label: "Installation Date",
          prop: "installation_date",
          sort: true,
          width: "120",
          align: "center",
          info: "Date on which device is assigned to the pond"
        },
        {
          label: "Start Date",
          prop: "start_date",
          sort: true,
          width: "100",
          align: "center",
          info:
            "Date on which subscription gets started which is based on device in Automode & Feeder dispenses feed"
        },
        {
          label: "End Date",
          prop: "end_date",
          sort: true,
          width: "100",
          align: "center",
          info: "Expiry of the subscription plan"
        },
        {
          label: "Jan",
          prop: "jan",
          sort: true,
          align: "center"
        },
        {
          label: "Feb",
          prop: "feb",
          sort: true,
          align: "center"
        },
        {
          label: "Mar",
          prop: "mar",
          sort: true,
          align: "center"
        },
        {
          label: "Apr",
          prop: "apr",
          sort: true,
          align: "center"
        },
        {
          label: "May",
          prop: "may",
          sort: true,
          align: "center"
        },
        {
          label: "Jun",
          prop: "jun",
          sort: true,
          align: "center"
        },
        {
          label: "Jul",
          prop: "jul",
          sort: true,
          align: "center"
        },
        {
          label: "Aug",
          prop: "aug",
          sort: true,
          align: "center"
        },
        {
          label: "Sep",
          prop: "sep",
          sort: true,
          align: "center"
        },
        {
          label: "Oct",
          prop: "oct",
          sort: true,
          align: "center"
        },
        {
          label: "Nov",
          prop: "nov",
          sort: true,
          align: "center"
        },
        {
          label: "Dec",
          prop: "dec",
          sort: true,
          align: "center"
        },
        {
          label: "Total",
          prop: "total",
          sort: true,
          align: "center",
          fixed: "right",
          width: "100"
        }
      ],
      tableData: {
        year: 2022,
        data: [],
        total_unnassinged_count: 0,
        total_unnassinged_details: 0
      },
      searchString: "",
      allCustomers: [],
      customer: "",
      sum: [],
      start_date: "2021-01-01",
      end_date: "2021-12-31",
      // Dialog Value
      gridPondDetailsTable: [
        {
          st_id: "ST80466",
          install_date: "23-Jan-2021",
          start_date: "23-Jan-2022",
          end_date: "23-Jan-2022"
        },
        {
          st_id: "ST80466",
          install_date: "23-Jan-2021",
          start_date: "23-Jan-2022",
          end_date: "23-May-2022"
        },
        {
          st_id: "ST80466",
          install_date: "23-Jan-2021",
          start_date: "23-Aug-2022",
          end_date: "23-Dec-2022"
        }
      ],
      dialogPondDetailsVisible: false,
      dialogUnassignedPondDetailsVisible: false,
      selectedPondId: "",
      selectedPondName: "",
      propsForAmountFormat: {
        jan: true,
        feb: true,
        mar: true,
        apr: true,
        may: true,
        jun: true,
        jul: true,
        aug: true,
        sep: true,
        oct: true,
        nov: true,
        dec: true,
        total: true
      }
    };
  },
  computed: {
    ...mapGetters("accountManager", {
      getDealerSubscriptions: "getDealerSubscriptions",
      getDealerCustomerSubscriptionReceivables:
        "getDealerCustomerSubscriptionReceivables",
      getDealerCustomerSubscriptionUnassignedDetails:
        "getDealerCustomerSubscriptionUnassignedDetails",
      getDealerCustomerSubscriptionUnassignedCount:
        "getDealerCustomerSubscriptionUnassignedCount",
      getDealerUsersDevices: "getDealerUsersDevices"
    }),

    filterTableData() {
      if (!this.searchString) return this.tableData;
      return this.tableData.filter((data) => {
        return data.title.toLowerCase().includes(this.searchString);
      });
    }
  },
  async mounted() {
    this.tableData.year = new Date().getFullYear();

    this.start_date = moment()
      .startOf("year")
      .format("YYYY-MM-DD");
    this.end_date = moment()
      .endOf("year")
      .format("YYYY-MM-DD");

    this.customer = this.$route.query.customer;
    this.handleLoadData();
  },
  methods: {
    ...mapActions("accountManager", {
      fetchAllSubscriptions: "fetchAllSubscriptions",
      fetchCustomerSubscriptionReceivables:
        "fetchCustomerSubscriptionReceivables",
      fetchDealerUsersAndDevices: "fetchDealerUsersAndDevices"
    }),
    async handleLoadData() {
      try {
        this.loading = true;

        this.loading = true;
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();

        for (let i = 6; i < 18; i++) {
          const monthIndex = i - 6;
          this.columns[i].is_month = true;
          if (this.tableData.year > currentYear) {
            this.columns[i].value_type = "projections";
          } else if (
            this.tableData.year === currentYear &&
            monthIndex > currentMonth
          ) {
            this.columns[i].value_type = "projections";
          } else {
            this.columns[i].value_type = "actuals";
          }
        }
        await this.fetchDealerUsersAndDevices({
          params: { get_all: true },
          infinite_scroll: false
        });
        this.allCustomers = this.getDealerUsersDevices;

        await this.fetchCustomerSubscriptionReceivables({
          id: this.customer,
          query: {
            order_by: "first_name",
            order_type: "asc",
            get_all: true,
            start_date: this.start_date,
            end_date: this.end_date
          }
        });

        const assignedData = this.prepareAssignedData();

        this.tableData.data = this.updateTableData(assignedData);

        this.tableData.total_unnassinged_count = this.getDealerCustomerSubscriptionUnassignedCount;

        this.loading = false;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },

    prepareAssignedData() {
      const assignedData = this.getDealerCustomerSubscriptionReceivables;

      if (assignedData.length > 0 && Object.keys(this.getDealerCustomerSubscriptionUnassignedDetails).length !== 0) {
        this.tableData.total_unnassinged_details = {
          ...this.getDealerCustomerSubscriptionUnassignedDetails
        };

        this.tableData.total_unnassinged_details.months = Object.values(
          this.tableData.total_unnassinged_details.months
        );
        this.tableData.total_unnassinged_details.location_name = "";
        this.tableData.total_unnassinged_details.location_id = "";
        this.tableData.total_unnassinged_details.pond_name = "Unassigned";
        this.tableData.total_unnassinged_details.pond_id = "UNASSIGNED";

        assignedData.push(this.tableData.total_unnassinged_details);
      }
      return assignedData;
    },
    showPondSTDetails(pondDetails) {
      if (pondDetails._id === "UNASSIGNED") {
        this.selectedPondId = pondDetails._id;
        this.selectedPondName = pondDetails.name;
        this.dialogUnassignedPondDetailsVisible = true;
        this.dialogPondDetailsVisible = false;
      } else {
        this.selectedPondId = pondDetails._id;
        this.selectedPondName = pondDetails.name;
        this.dialogPondDetailsVisible = true;
        this.dialogUnassignedPondDetailsVisible = false;
      }
    },
    getCellClass({ row, column, rowIndex, columnIndex }) {
      const selectedColumn = this.columns[columnIndex];
      return selectedColumn.value_type || "";
    },

    downloadTableData(data) {
      let csv = this.columns.map((e) => e.label).join(",") + "\n";

      csv = "Customer," + csv;
      // merge the data with CSV

      this.tableData.data.forEach((row) => {
        const customerData = this.allCustomers.find(
          (x) => x._id === this.customer
        );
        let rowdata =
          customerData.first_name +
          " " +
          customerData.last_name +
          " " +
          customerData.email +
          ",";
        this.columns.forEach((e) => {
          if (row[e.prop]) {
            rowdata =
              rowdata +
              (e.prop === "pond" || e.prop === "location"
                ? row[e.prop].name
                : row[e.prop]) +
              ",";
          } else {
            rowdata = rowdata + "" + ",";
          }
        });
        rowdata = rowdata + "\n";

        csv += rowdata;
      });

      csv += "-," + this.sums.join(",") + "\n";

      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";

      // provide the name for the CSV file to be downloaded
      hiddenElement.download =
        "ST_CUSTOMER_Total_Receivables_" +
        this.start_date +
        "_" +
        this.end_date +
        ".csv";
      hiddenElement.click();
    },
    async handleSearch(queryObj) {
      if (queryObj.year) {
        this.start_date = moment(queryObj.year)
          .startOf("year")
          .format("YYYY-MM-DD");
        this.end_date = moment(queryObj.year)
          .endOf("year")
          .format("YYYY-MM-DD");

        this.tableData.year = new Date(queryObj.year).getFullYear();
      } else {
      this.rdm__navigateToPathWithCustomerId({ query: { customer: queryObj } })
      }
      await this.handleLoadData();
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      let monthCols = this.columns.slice(6, 19);
      monthCols = monthCols.map((e) => e.prop);
      columns.forEach((column, index) => {
        if (
          column.property === "pond" ||
          column.property === "installation_date" ||
          column.property === "start_date" ||
          column.property === "end_date"
        ) {
          sums[index] = "";
          return;
        }
        if (column.property === "location") {
          sums[index] = "Total ShrimpTalks";
          return;
        }

        const values = data.map((item) => {
          if (item[column.property]) {
            const value = item[column.property]; // .toString().replace("$ ", "")
            return Number(value);
          } else {
            return Number(item[column.property]);
          }
        });

        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);

          if (monthCols.indexOf(column.property) > -1) {
            sums[index] = this.getLocaleString(sums[index]);
          }
        } else {
          sums[index] = "-";
        }
      });

      this.sums = sums;
      return sums;
    },

    updateTableData(data) {
      const tableData = [];
      const monthMap = {
        "01": "jan",
        "02": "feb",
        "03": "mar",
        "04": "apr",
        "05": "may",
        "06": "jun",
        "07": "jul",
        "08": "aug",
        "09": "sep",
        10: "oct",
        11: "nov",
        12: "dec"
      };
      data.forEach((pondData) => {
        const obj = {
          location: { name: pondData.location_name, _id: pondData.location_id },
          pond: { name: pondData.pond_name, _id: pondData.pond_id },
          total_sts: pondData.shrimp_talks_count,
          installation_date: pondData.min_installed_date
            ? moment(pondData.min_installed_date).format("DD-MMM-YY")
            : "-",
          start_date: pondData.subscription_start_date
            ? moment(pondData.subscription_start_date).format("DD-MMM-YY")
            : "-",
          end_date: pondData.subscription_end_date
            ? moment(pondData.subscription_end_date).format("DD-MMM-YY")
            : "-",
          total: pondData.total_amount
        };

        pondData.months.forEach((e) => {
          obj[monthMap[e.month]] = e.amount;
        });

        tableData.push(obj);
      });

      return tableData;
    },
    // maybe in feature currency will change based on customer. So implemented this function.
    getCurrencyType() {
      return "$ ";
    },
    getLocaleString(amount = 0) {
      return this.getCurrencyType() + amount.toLocaleString()
    },
  }
};
</script>

<style lang="scss">
.account-manager-customer-pond-summary {
  height: 100%;
  .el-table--enable-row-hover {
    .el-table__body {
      tr {
        td.actuals {
          background-color: #ebffe2;
        }
        td.projections {
          background-color: #ebebff;
        }
        &:hover > td.el-table__cell {
          background-color: #fafafa;
        }
        &:hover > td.actuals {
          background-color: #dffad2;
        }
        &:hover > td.projections {
          background-color: #dfdffd;
        }
      }
    }
  }
  .el-table th > .cell {
      font-weight: bold;
      color: #233a73;
      line-height: 120%;
      font-size: 11px;
  }
  .el-card__body {
    margin-top: 20px;
  }
  .el-table td.el-table__cell {
    border-bottom: 1px solid #e2e5eb;
  }

  .el-table__body-wrapper {
    height: inherit;
    overflow: auto;
  }
  .el-date-editor {
    .el-input__icon {
      line-height: 29px;
    }
  }
  .el-date-editor.el-input {
    width: 196px !important;
  }
  .pond-details-dialog {
    .el-dialog__header {
      // border: 1px solid #efefef;
      .el-dialog__title {
        font-size: 16px;
        color: #3c3cf8;
      }
    }
    .el-dialog__body {
      padding: 5px 15px !important;
      min-height: auto;
      border-radius: 10px !important;
      background-color: #fafafa;
    }
  }
  .el-dialog {
    border-radius: 10px !important;
  }
}
</style>

<style lang="scss" scoped>
// This is updated file
.location-name {
  text-transform: capitalize;
}
.text-button {
  padding: 0;
  color: #3c3cf8;
  font-size: inherit;
}
.action-button {
  position: absolute;
  top: 16px;
  right: 55px;
  text-align: right;
  .el-button {
    line-height: 1;
    padding: 5px 8px;
    font-size: 1em;
    height: auto;
  }
}

.table-info {
  padding: 10px;
  font-size: 11px;
}
.table-legends {
  display: flex;
  gap: 10px;
  padding: 8px 10px;
  justify-content: flex-end;
  .icon {
    display: inline-block;
    height: 10px;
    width: 30px;
  }
  span {
    text-transform: uppercase;
    font-size: 12px;
  }
  .projections {
    background-color: #ffffff;
    display: flex;
    gap: 5px;
    padding: 2px 4px 2px 2px;
    align-items: center;
    border: 1px solid #efefef;
    .icon {
      background: #e0e0fb;
      border: 1px solid #e0e0fb;
      border-radius: 2px;
    }
  }
  .actuals {
    background-color: #ffffff;
    display: flex;
    gap: 5px;
    padding: 2px 4px 2px 2px;
    align-items: center;
    border: 1px solid #efefef;
    .icon {
      background: #d8fdc8;
      border: 1px solid #d8fdc8;
      border-radius: 2px;
    }
  }
}
</style>
